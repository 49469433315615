import { mapState } from 'vuex'
import { Component, Vue, Watch } from 'vue-property-decorator'
import ChangeLog from '@/modules/common/components/changelog/changelog.vue'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import FormPage from '@/interfaces/form-page.interface'

@Component({
  name: 'GtrRegistrationModuleFormView',
  computed: {
    ...mapState('option', ['option_groups']),
    ...mapState('formbuilder', ['pages', 'unusedFields', 'form', 'eventFields']),
    ...mapState('event', ['eventAllContent', 'changelog', 'currentlyDeployedLiveUUID', 'currentDevUUID', 'participant_fields']),
    ...mapState('module', ['activatedEventModules'])
  },
  components: {
    changelog: ChangeLog
  }
})
export default class GtrRegistrationModuleFormView extends Vue {
  public option_groups!: Record<string, any>
  public pages!: Record<string, any>
  public changelog!: Record<string, any>
  public form!: Record<string, any>
  public activatedEventModules!: Record<string, any>;
  private hiddenFields: Array<Record<string, any>> = [];

  get existingFields () {
    const fieldsOnForm = this.getFieldsOnForm()
    const allowedFields = this.getAllowedFields((this as any).eventFields)
    const fieldsNotOnForm = this.getFieldsNotOnForm(fieldsOnForm, (this as any).eventFields, allowedFields)
    const combinedFields = [...fieldsNotOnForm, ...this.$data.fieldsDeleted]
    const sortedExistingFields = this.sortExistingFields(combinedFields)
    return sortedExistingFields.map(field => this.convertOldShowIfToNewFormat(field))
  }

  get statesOptionGroupUuid () {
    return this.option_groups.filter(group => group.name === 'US States')[0]?.uuid ?? ''
  }

  get countriesOptionGroupUuid () {
    return this.option_groups.filter(group => group.name === 'Countries')[0]?.uuid ?? ''
  }

  get sessionsOptionGroupUuid () {
    return this.option_groups.filter(group => group.name === 'Sessions')[0]?.uuid ?? ''
  }

  get trackActivated (): boolean {
    return this.activatedEventModules?.SESSION_TRACKING?.enabled ?? false
  }

  // #endregion

  // #region Computed Props
  get contentAndFormLoaded () {
    return [this.$data.eventContent, this.$data.formContent]
  }

  get statusOptions (): string[] {
    for (let i = 0; i < this.$data.glossaryItems.length; i++) {
      if (this.$data.glossaryItems[i].field === 'status') {
        return this.$data.glossaryItems[i].options
      }
    }
    return []
  }

  get regUrl (): string {
    if (this.$data.eventContent) {
      return `${process.env.VUE_APP_REG_URL}/${this.$data.eventContent.event.event_identifier}`
    }
    return ''
  }

  data () {
    return {
      loading: false,
      submitting: false,
      languageToUse: 'en',
      formPollingId: null,
      formModel: {
        formVersion: 'default',
        tab: null,
        page_data: [] as FormPage[]
      },
      choices: {
        languages: [],
        optionGroupItems: [],
        fieldOptionGroupItems: [],
        optionGroupOptionsByUUID: {},
        questionTypeItems: [
          {
            text: 'Text Field',
            value: 'text'
          },
          {
            text: 'Radio Buttons',
            value: 'radio'
          },
          {
            text: 'Checkboxes',
            value: 'checkbox'
          },
          {
            text: 'Dropdown',
            value: 'dropdown'
          },
          {
            text: 'Paragraph',
            value: 'textarea'
          },
          {
            text: 'HTML Block',
            value: 'html'
          },
          {
            text: 'File Upload',
            value: 'file'
          },
          {
            text: 'Date',
            value: 'date'
          },
          {
            text: 'Time',
            value: 'time'
          },
          {
            text: 'Payment',
            value: 'payment'
          },
          {
            text: 'Promo Code',
            value: 'promocode'
          },
          {
            text: 'Set Field',
            value: 'setfield'
          },
          {
            text: 'Sessions',
            value: 'sessions'
          }
        ],
        payment_processors: [
          {
            text: 'Stripe',
            value: 'stripe'
          },
          {
            text: 'Authorize.net',
            value: 'authnet'
          }
        ],
        payment_types: [
          {
            text: 'Charge',
            value: 'charge'
          },
          {
            text: 'Vault',
            value: 'vault'
          }
        ],
        maskItems: [
          {
            text: 'Date ####-##-##',
            value: '####-##-##'
          },
          {
            text: 'Time ##:##:##',
            value: '##:##:##'
          },
          {
            text: 'Date Time ##/##/#### ##:##:##',
            value: '##/##/#### ##:##:##'
          },
          {
            text: 'Zip Code #####',
            value: '#####'
          },
          {
            text: 'Phone (###) ###-####',
            value: '(###) ###-####'
          },
          {
            text: 'Alphabetic',
            value: 'alphabeticMask'
          },
          {
            text: 'Numeric',
            value: 'numericMask'
          }
        ]
      },
      changeLogModel: {
        changeLog: [],
        currentDevUUID: null,
        currentlyDeployedLiveUUID: null
      },
      eventContent: null,
      formContent: null,
      selectedItem: 1,
      glossaryItems: [],
      duplicateFieldLabels: false,
      existingFieldsSearchQuery: '',
      fieldsDeleted: []
    }
  }

  // #region Life Cicle Callbacks
  async mounted () {
    await this.fetchData()
    this.startFormPolling()
  }

  beforeDestroy () {
    this.stopFormPolling()
  }

  getAllowedFields (allFields: any[] = []) {
    const allowedFields = ['first_name', 'last_name', 'email', 'company', 'title', 'address', 'address_2', 'city', 'state', 'zip_code', 'country', 'phone_number', 'profile_photo']
    if (this.trackActivated) {
      allowedFields.push(this.sessionsOptionGroupUuid)
    }
    const notAllowedParticipantFields = ['registration_number']
    const participantFields = allFields.filter(field => !field.standard_field && !notAllowedParticipantFields.includes(field.field) && (field.table === 'participants' || field.cc_email))
    const participantFieldsHash = {}
    const participantFieldsWithoutDuplicates = participantFields.reduce((acc, current) => {
      if (!current.option_group_uuid) {
        acc.push(current)
      } else {
        if (participantFieldsHash[current.option_group_uuid] === undefined) {
          participantFieldsHash[current.option_group_uuid] = acc.push(current) - 1
        } else {
          const index = participantFieldsHash[current.option_group_uuid]
          if (new Date(current.created_at).getTime() > new Date(acc[index].created_at).getTime()) {
            acc[index] = current
          }
        }
      }
      return acc
    }, [])
    return [...allowedFields, ...participantFieldsWithoutDuplicates.map(field => field.field)]
  }

  getFieldsOnForm () {
    return this.$data.formModel.page_data.flatMap(
      page => page.fields)
  }

  getFieldsNotOnForm (fieldsOnForm: any[] = [], allFields: any[] = [], allowedFields: any[] = []) {
    const fieldNamesOnForm = fieldsOnForm.map(field => field.field)
    return allFields.filter(field => (!fieldNamesOnForm.includes(field.field) && allowedFields.includes(field.field)))
  }

  sortExistingFields (combinedExistingFields: any[] = []) {
    return combinedExistingFields.sort((a, b) => {
      const labelA = typeof a.label === 'string' ? a.label : a.label[this.$data.languageToUse]
      const labelB = typeof b.label === 'string' ? b.label : b.label[this.$data.languageToUse]
      return labelA.localeCompare(labelB)
    })
  }

  updateExistingFieldsHeight () {
    setTimeout(() => {
      const existingFieldsContainer = document.querySelector(`#${this.$data.formModel.tab} #existing-fields-container`)
      const formContainer = document.querySelector(`#${this.$data.formModel.tab} #form-container`)
      if (existingFieldsContainer && formContainer) {
        (existingFieldsContainer as any).style.height = formContainer.clientHeight.toString() + 'px'
      }
    }, 0)
  }

  toggleShow (field: Record<string, any>): void {
    field.visible = !field.visible

    // if field.showMenu is true that means hide it, so set required to false.
    // This is because of a backwards compatibility issue. All old forms
    // default to false.
    if (field.visible === false) {
      // if the field is explicitly not visible
      // set required to false
      field.required = false
    }
  }

  convertOldShowIfToNewFormat (settings) {
    if (settings?.use_show_if) {
      const mergedShowIfObject = Object.assign({},
        {
          global_type: settings.show_if_type,
          visible: !!settings.use_show_if,
          type: '',
          field: '',
          operator: '',
          value: '',
          group_operator: '',
          group_items: []
        }, settings.show_if)
      settings = Object.assign({}, settings, { show_if: mergedShowIfObject })
      delete settings.show_if_type
      delete settings.use_show_if
    }
    return settings
  }

  toggleRequired (field: Record<string, any>): void {
    // toggle required.
    field.required = !field.required
    // if field.showMenu is true that means hide it, so set to false.
    // This is because of a backwards compatibility issue. All old forms
    // default to false.

    if (field.required && field.visible === false) {
      // if the field is required AND it is explicitly not visible.
      // set visible to true.
      field.visible = true
    }
  }

  toggleShowOnlyParent (field: Record<string, any>): void {
    field.show_only_parent = !field.show_only_parent
  }

  // #region Watchers
  @Watch('formModel.tab', { immediate: true })
  onFormModelTab () {
    this.updateExistingFieldsHeight()
  }

  @Watch('form', { immediate: true })
  onFormChange (payload: any) {
    if (payload.page_data) {
      this.$data.formContent = payload
      for (const page of payload.page_data) {
        page.settings = Object.assign({}, this.convertOldShowIfToNewFormat(page.settings))
        page.fields = page.fields.map(field => this.convertOldShowIfToNewFormat(field))
        // For older form pages, we want to add new visible property to page settings
        if (!page.settings.hasOwnProperty('visible')) {
          Object.assign(page.settings, { visible: true })
        }
        for (const field of page.fields) {
          // field = Object.assign({}, this.convertOldShowIfToNewFormat(field))
          if (field.visible === undefined) {
            field.visible = true
          }
          if (this.option_groups && this.optionGroupIsHidden(field.option_group_uuid)) {
            field.visible = false
            field.required = false
          }
        }
      }
      this.$data.formModel.page_data = payload.page_data
    }
  }

  @Watch('option_groups')
  onOptionGroupsChange (payload: any) {
    if (payload) {
      const sortedOptionGroups: any[] = []
      for (let i = 0; i < payload.length; i++) {
        if (!payload[i].group_display && this.$data.formModel.page_data.length) {
          for (const page of this.$data.formModel.page_data) {
            for (const field of page.fields) {
              if (field.option_group_uuid === payload[i].uuid) {
                field.visible = false
                field.required = false
              }
            }
          }
        }
        if (payload[i].options.length > 0) {
          sortedOptionGroups.push({
            text: payload[i].name,
            value: payload[i].uuid
          })
          if (payload[i].name !== 'Registration Types') {
            sortedOptionGroups.push({
              text: payload[i].name,
              value: payload[i].uuid,
              full_data: payload[i]
            })
          }
          const sortedOptions: any[] = []
          for (let x = 0; x < payload[i].options.length; x++) {
            sortedOptions.push({
              text: payload[i].options[x].name,
              value: payload[i].options[x].uuid
            })
          }
          sortedOptions.sort((a, b) => a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1)
          this.$data.choices.optionGroupOptionsByUUID[payload[i].uuid] = sortedOptions
        }
      }
      sortedOptionGroups.sort((a, b) => a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1)
      this.$data.choices.optionGroupItems = sortedOptionGroups
    }
  }

  @Watch('eventFields')
  onEventFieldsChange (payload: any) {
    if (Array.isArray(payload)) {
      const sortedFields: any[] = []
      payload.forEach(field => {
        sortedFields.push({
          text: this.getLabel(field),
          value: field.field
        })
      })
      sortedFields.sort((a, b) => a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1)
      this.$data.choices.showIfFieldSelections = sortedFields
    }
  }

  @Watch('eventAllContent', { immediate: true })
  onEventAllContentChange (payload: any) {
    if (payload && payload.languages) {
      this.$data.eventContent = payload
      this.$data.choices.languages = []
      for (const language_key in payload.languages) {
        this.$data.choices.languages.push({
          text: payload.languages[language_key],
          value: language_key
        })
      }
    }
  }

  @Watch('changelog')
  onChangeLogChange (payload: any) {
    if (payload.data) {
      this.$data.changeLogModel.changeLog = payload.data
    }
  }

  @Watch('currentDevUUID')
  onCurrentDevUUIDChange (payload: any) {
    if (payload.dev) {
      this.$data.changeLogModel.currentDevUUID = payload.dev.uuid
    }
  }

  @Watch('currentlyDeployedLiveUUID')
  onCurrentlyDeployedLiveUUIDChange (payload: any) {
    if (payload.live) {
      this.$data.changeLogModel.currentlyDeployedLiveUUID = payload.live.uuid
    }
  }

  // #endregion

  @Watch('formModel.formVersion', { deep: true })
  onFormRevisionChange (payload: any) {
    if (payload !== 'default') {
      this.$data.formModel.page_data = this.$data.changeLogModel.changeLog.filter((row: any) => row.uuid === payload).pop().page_data
      Container.get(Notification).success('Form version successfully changed.')
    }
  }

  @Watch('contentAndFormLoaded')
  onContentAndFormLoadedChange (payload: any) {
    /**
     * Wait until both allContent and form are loaded
     */
    const [eventContent, formContent] = payload
    if (!eventContent || !formContent) return
    let counter = 0
    for (const language_key in eventContent.languages) {
      /**
       * Make the first language in the object the default
       */
      if (counter === 0) {
        // this.$data.languageToUse = language_key
        this.$data.languageToUse = 'en'
      }
      counter++
      /**
       * Define the language indexes beforehand for froala
       * If we don't, when switching languages, the text editor will keep the old value loaded in the text editor
       * If html doesn't have a specific language key, add it
       */
      if (this.$data.formModel.page_data) {
        this.$data.formModel.page_data.forEach(page => {
          page.fields.forEach((field: any) => {
            if (field.html && !field.html[language_key]) {
              field.html[language_key] = ''
            }
            field.show_mask = !!field.mask
            if (field.show_read_only_if_complete === undefined) {
              field.show_read_only_if_complete = false
            }
            if (field.show_read_only_if_set === undefined) {
              field.show_read_only_if_set = false
            }
            if (field.show_only_parent === undefined) {
              field.show_only_parent = false
            }
          })
        })
      }
    }
  }

  // #endregion

  // toggleRequired (field: Record<string, any>): void {
  //   field.required = !this.isRequired(field)
  // }

  // toggleShow (field: Record<string, any>): void {
  //   field.showMenu = !field.showMenu
  // }

  // private isShow (field: Record<string, any>): boolean {
  //   return field.showMenu
  // }

  // private isRequired (field: Record<string, any>): boolean {
  //   return (field.required === true || field.required === '1')
  // }

  // #region Methods
  handleExistingFieldsDragEnd (event: any) {
    const uuid = event.item.getAttribute('data-uuid')
    const index = this.$data.fieldsDeleted.findIndex((field: any) => field.field === uuid)
    if (index) {
      this.$data.fieldsDeleted.splice(index, 1)
    }
  }

  optionGroupIsHidden (uuid: string): boolean {
    if (uuid) {
      const groups = this.option_groups
      for (let i = 0; i < groups.length; i++) {
        const group = groups[i]
        if (group.uuid === uuid) {
          return !group.group_display
        }
      }
    }
    return false
  }

  handleUpdateFormVersion (payload: any) {
    if (payload) {
      this.$data.formModel.formVersion = payload
      this.$data.changeLogModel.currentDevUUID = payload
    }
  }

  handleMoveCallback (payload: any) {
    if (payload.draggedContext.futureIndex >= this.$data.formModel.page_data.length - 1) {
      return false
    } else {
      const newField = this.$data.formModel.page_data[payload.draggedContext.futureIndex]
      Vue.set(this.$data.formModel, 'tab', `tab${newField.uuid}`)
    }
  }

  // TODO(zb): Revisit this. When you add a field from existing fields, a lot of extra fields get inserted with it into the on-form fields.
  handleFieldChange (event: any) {
    /* eslint-disable */
    const { added: { element: newField = null } = {} } = event
    if (newField) {
      const { field } = newField
      if (['state', 'country', 'zip_code', 'phone_number'].includes(field)) {
        Vue.set(newField, 'required', '1')
        Vue.set(newField, 'visible', true)
      }
      if (field === 'state') {
        Vue.set(newField, 'type', 'dropdown')
        Vue.set(newField, 'option_group_uuid', this.statesOptionGroupUuid)
      } else if (field === 'country') {
        Vue.set(newField, 'type', 'dropdown')
        Vue.set(newField, 'option_group_uuid', this.countriesOptionGroupUuid)
      } else if (field === 'zip_code') {
        Vue.set(newField, 'mask', '#####')
        Vue.set(newField, 'show_mask', true)
        Vue.set(newField, 'type', 'text')
      } else if (field === 'phone_number') {
        Vue.set(newField, 'mask', '(###) ###-####')
        Vue.set(newField, 'show_mask', true)
        Vue.set(newField, 'type', 'text')
      } else if (field === 'Alphabetic') {
        Vue.set(newField, 'mask', 'alphabeticMask')
        Vue.set(newField, 'show_mask', true)
        Vue.set(newField, 'type', 'text')
      } else if (field === 'Numeric') {
        Vue.set(newField, 'mask', 'numericMask')
        Vue.set(newField, 'show_mask', true)
        Vue.set(newField, 'type', 'text')
      } else if (field === this.sessionsOptionGroupUuid) {
        Vue.set(newField, 'type', 'sessions')
        Vue.set(newField, 'option_group_uuid', this.sessionsOptionGroupUuid)
      }
      Vue.set(newField, 'visible', true)
      Vue.set(newField, 'required', "1")
    }
  }



  handleEnd(payload: any) {
    const newIndex = payload.newIndex
    Vue.set(this.$data.formModel, 'tab', `tab${this.$data.formModel.page_data[newIndex].uuid}`)
  }

  handleMakeTabActive(payload: any) {
    this.$data.formModel.page_data = this.$data.formModel.page_data.map(page => {
      if (page.uuid === payload.uuid) {
        Vue.set(this.$data.formModel, 'tab', `tab${page.uuid}`)
        return { ...page, isActive: true }
      } else {
        return { ...page, isActive: false }
      }
    })
  }

  handleDeletePage(uuid: string) {
    if (uuid && this.$data.formModel.page_data.length >= 1) {
      const pageToDelete = this.$data.formModel.page_data.find(p => p.uuid === uuid)
      let pageToDeleteIndex = this.$data.formModel.page_data.findIndex(p => p.uuid === uuid)
      const newPageData = this.$data.formModel.page_data.filter((page: any) => page.uuid !== uuid)
      Vue.set(this.$data.formModel, 'page_data', newPageData)
      if (pageToDelete?.isActive) {
        const allPagesBesidesReceipt = this.$data.formModel.page_data.filter(p => p.page_type !== 'RECEIPT')
        if (pageToDeleteIndex < 0) {
          pageToDeleteIndex = 0
        }
        this.handleMakeTabActive(this.$data.formModel.page_data[pageToDeleteIndex - 1])
      }
    }
  }

  handleAddShowIf(fieldOrPage: any) {
    fieldOrPage.show_if = {
      visible: true,
      global_type: '',
      type: '',
      field: '',
      operator: '',
      value: '',
      group_operator: '',
      group_items: []
    }
  }

  handleAddMask(payload: any, page: any) {
    if (payload && page) {
      const index = page.fields.indexOf(payload)
      payload.show_mask = true
      Vue.set(page.fields, index, payload)
    }
  }

  handleDeleteMask(payload: any, page: any) {
    if (payload && page) {
      const index = page.fields.indexOf(payload)
      payload.show_mask = false
      delete payload.mask
      Vue.set(page.fields, index, payload)
    }
  }

  handleAddCharLimit(payload: any, page: any) {
    if (payload && page) {
      const index = page.fields.indexOf(payload)
      payload.show_char_limit = true
      Vue.set(page.fields, index, payload)
    }
  }

  handleDeleteCharLimit(payload: any, page: any) {
    if (payload && page) {
      const index = page.fields.indexOf(payload)
      payload.show_char_limit = false
      delete payload.char_limit
      Vue.set(page.fields, index, payload)
    }
  }

  handleAddReadOnlyIfComplete(payload: any, page: any) {
    if (payload && page) {
      const index = page.fields.indexOf(payload)
      payload.show_read_only_if_complete = true
      Vue.set(page.fields, index, payload)
    }
  }

  handleAddReadOnlyIfSet(payload: any, page: any) {
    if (payload && page) {
      const index = page.fields.indexOf(payload)
      payload.show_read_only_if_set = true
      Vue.set(page.fields, index, payload)
    }
  }

  handleAddShowOnlyOnParentSet(payload: any, page: any) {
    if (payload && page) {
      const index = page.fields.indexOf(payload)
      payload.show_only_parent = true
      Vue.set(page.fields, index, payload)
    }
  }

  handleRemoveReadOnlyIfComplete(payload: any, page: any) {
    if (payload && page) {
      const index = page.fields.indexOf(payload)
      payload.show_read_only_if_complete = false
      delete payload.read_only_if_complete
      Vue.set(page.fields, index, payload)
    }
  }

  handleRemoveReadOnlyIfSet(payload: any, page: any) {
    if (payload && page) {
      const index = page.fields.indexOf(payload)
      payload.show_read_only_if_set = false
      delete payload.read_only_if_set
      Vue.set(page.fields, index, payload)
    }
  }

  handleAddField() {
    const currentPage = this.$data.formModel.page_data.find((page: any) => page.isActive)
    if (currentPage) {
      currentPage.fields.push({
        field: Vue.prototype.$uuid.v4(),
        type: 'text',
        required: '1',
        label: { [this.$data.languagetouse]: '' },
        storage_type: 'text',
        show_if: {
          visible: false,
          global_type: '',
          type: '',
          field: '',
          operator: '',
          value: '',
          group_operator: '',
          group_items: []
        },
        showMenu: true,
        payment_info: null,
        payment_processor: null,
        payment_type: null,
        visible: true
      })
      return false
    }
    this.updateExistingFieldsHeight()
  }

  handleAddCCField() {
    const currentPage = this.$data.formModel.page_data.find((page: any) => page.isActive)
    if (currentPage) {
      currentPage.fields.push({
        field: Vue.prototype.$uuid.v4(),
        type: 'text',
        required: '1',
        label: { en: 'Enter a CC recipient' },
        storage_type: 'text',
        show_if: {
          visible: false,
          global_type: '',
          type: '',
          field: '',
          operator: '',
          value: '',
          group_operator: '',
          group_items: []
        },
        showMenu: true,
        payment_info: null,
        payment_processor: null,
        payment_type: null,
        visible: true,
        cc_email: true
      })
      return false
    }
    this.updateExistingFieldsHeight()
  }

  handleDeleteField(page: any, field_name: string) {
    page.fields = page.fields.filter((field: any) => field.field !== field_name)
    this.updateExistingFieldsHeight()
  }

  allowDelete(page: any) {
    const index = this.$data.formModel.page_data.indexOf(page)
    const fields = this.$data.formModel.page_data[index].fields
    for (const fieldIndex in fields) {
      const fieldCur = fields[fieldIndex]
      if (fieldCur.field === 'first_name') {
        return false
      }
    }
    return index !== 0 && index !== this.$data.formModel.page_data.length - 1
  }

  /**
   * Detemines which form pages have ability to hide/show
   * @param index
   * @returns page index not 0 and not last in array
   */
  allowHideShowPage(page: FormPage) {
    return this.$data.formModel.page_data.length >= 1 && page.page_type !== 'RECEIPT'
  }

  handleAddPage() {
    /**
     * Add page right before index page
     */
    this.$data.formModel.page_data.splice(this.$data.formModel.page_data.length - 1, 0, {
      isActive: false,
      page_type: 'STANDARD',
      uuid: Vue.prototype.$uuid.v4(),
      prefix: '',
      suffix: '',
      settings: {
        name: 'Page ' + (this.$data.formModel.page_data.length).toString(), // page before the receipt page
        show_if: {
          visible: false,
          global_type: '',
          type: '',
          field: '',
          operator: '',
          value: '',
          group_operator: '',
          group_items: []
        },
        showMenu: true,
        visible: true
      },
      fields: []
    })
    Vue.set(this.$data.formModel.page_data[this.$data.formModel.page_data.length - 1], 'page_type', 'RECEIPT')
  }

  handleAddGroupRegistration() {
    /**
     * Make the new page be the active tab
     */
    for (let i = 0; i < this.$data.formModel.page_data.length; i++) {
      if (this.$data.formModel.page_data[i].uuid === 'group_registration_page') {
        Container.get(Notification).error('There is already a group registration page')
        return false
      }
    }
    this.$data.formModel.page_data.splice(this.$data.formModel.page_data.length - 1, 0, {
      isActive: false,
      page_type: 'STANDARD',
      uuid: 'group_registration_page',
      prefix: '',
      suffix: '',
      settings: {
        name: 'Group Registration',
        show_if: {
          visible: false,
          global_type: '',
          type: '',
          field: '',
          operator: '',
          value: '',
          group_operator: '',
          group_items: []
        },
        showMenu: true,
        visible: true
      },
      fields: [
        {
          field: 'pay_for_another',
          type: 'text',
          required: '1',
          label: {
            [this.$data.languageToUse]: 'Pay For Another'
          },
          storage_type: 'text',
          show_if: {
            visible: false,
            global_type: '',
            type: '',
            field: '',
            operator: '',
            value: '',
            group_operator: '',
            group_items: []
          },
          showMenu: true
        }
      ]
    })
    this.updateExistingFieldsHeight()
  }

  handleAddPaymentPage() {
    /**
     * Make the new page be the active tab
     */
    for (let i = 0; i < this.$data.formModel.page_data.length; i++) {
      if (this.$data.formModel.page_data[i].fields) {
        const fields = this.$data.formModel.page_data[i].fields
        for (let j = 0; j < fields.length; j++) {
          const field = fields[j]
          if (field.type === 'payment') {
            Container.get(Notification).error('There is already a payment page')
            return false
          }
        }
      }
    }
    const [paymentOptionGroup] = this.option_groups.filter(group => group.name === 'Payment Methods')
    if (!paymentOptionGroup) {
      Container.get(Notification).error('Payment Methods option group is missing')
      return false
    }
    const [creditCardOption] = paymentOptionGroup.options.filter(option => option.name === 'Credit Card')
    if (!creditCardOption) {
      Container.get(Notification).error('Credit Card option is missing')
      return false
    }
    this.$data.formModel.page_data.splice(this.$data.formModel.page_data.length - 1, 0, {
      isActive: false,
      page_type: 'STANDARD',
      uuid: 'payment_page',
      prefix: '',
      suffix: '',
      settings: {
        name: 'Payment Page',
        show_if: {
          visible: true,
          global_type: 'single',
          type: 'field_criteria',
          field: 'cur_reg_fee',
          operator: '>',
          value: '0',
          group_operator: '',
          group_items: []
        },
        showMenu: true,
        visible: true
      },
      fields: [
        {
          field: 'payment_method',
          type: 'dropdown',
          required: '1',
          label: {
            [this.$data.languageToUse]: 'Payment Method'
          },
          option_group_uuid: paymentOptionGroup.uuid,
          storage_type: 'text',
          show_if: {
            visible: false,
            global_type: '',
            type: '',
            field: '',
            operator: '',
            value: '',
            group_operator: '',
            group_items: []
          },
          showMenu: true
        },
        {
          field: 'payment_details',
          type: 'payment',
          required: '1',
          label: {
            [this.$data.languageToUse]: 'Payment'
          },
          storage_type: 'text',
          show_if: {
            visible: true,
            global_type: 'single',
            type: 'option_criteria',
            field: paymentOptionGroup.uuid,
            operator: '=',
            value: creditCardOption.uuid,
            group_operator: '',
            group_items: []
          },
          showMenu: true
        }
      ]
    })
  }

  isFieldADefaultField(field_name: string) {
    const default_fields = ['first_name', 'last_name', 'email', 'pay_for_another']
    return default_fields.includes(field_name)
  }

  handleOptionGroupChange(optionGroupUuid: string, field: Record<string, any>): void {
    const groups = this.option_groups
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i]
      // if the group id is the and group display if false
      if (group.uuid === optionGroupUuid) {
        if (!group.group_display) {
          // turn off visibility
          field.visible = false
          if (field.required) {
            // also if it's required turn that off too
            field.required = false
          }
        }
      }
    }
  }

  makeChangesBasedOnFieldType(val: string, field: any) {
    /**
     * html fields need a localized object
     * other field types do not, so delete it if it is not html
     */
    if (val === 'html') {
      Vue.set(field, 'html', {})
      this.$data.choices.languages.forEach((language: any) => {
        Vue.set(field.html, language.value, '')
      })
    } else {
      delete field.html
    }

    if (val === 'sessions') {
      Vue.set(field, 'option_group_uuid', this.sessionsOptionGroupUuid)
    }

    if (val !== 'text' && val !== 'textarea') {
      field.show_char_limit = false
      delete field.char_limit
    }

    /**
     * Delete payment fields if the field type has changed
     */
    if (val === 'payment') {
      /**
       * Add payment info array to use on the Register.vue side
       * payment_type and payment_info are added via v-model
       */
      Vue.set(field, 'payment_info', {})
      Vue.set(field, 'payment_type', null)
      Vue.set(field, 'payment_processor', null)

      /**
       * For payment to work, the field name must be payment_details
       */
      field.field = 'payment_details'

      /**
       * Need a unique widget id
       */
      field.widget_id = Vue.prototype.$uuid.v4()
    } else {
      delete field.payment_processor
      delete field.payment_type
      delete field.payment_info
      delete field.widget_id
    }

    if (field.type !== 'dropdown' && field.type !== 'radio' && field.type !== 'checkbox' && field.type !== 'sessions') {
      /**
       * If the field is no longer one of the above, remove option group id that the field was tied to
       */
      delete field.option_group_uuid
    }

    if (field.type !== 'text') {
      field.show_mask = false
    }

    if (!this.allowShowReadOnlyIfCompleteOrReadOnlyIfSet(field)) {
      field.show_read_only_if_complete = false
      field.show_read_only_if_set = false
      delete field.read_only_if_complete
      delete field.read_only_if_set
    }
  }

  isDefaultPage(page: any) {
    return this.$data.formModel.page_data.indexOf(page) === 0
  }

  async handleSaveForm() {
    try {
      this.$data.submitting = true
      this.$store.dispatch('common/showLoader', { value: true })
      /**
       * Assign field names
       */
      const form = this.$data.formModel.page_data
      // let default_fields = ['first_name', 'last_name', 'email'];
      for (let i = 0; i < form.length; i++) {
        /**
         * BE wants an empty array for no show if
         */
        if (!form[i]?.settings?.show_if?.type) {
          form[i].settings.show_if = []
        }
        form[i].fields.forEach((field: any) => {
          if (typeof field.label === 'string') {
            field.label = { [this.$data.languageToUse]: field.label }
          }

          /**
           * BE wants an empty array for no show if
           */
          if (!field.show_if?.type) {
            field.show_if = []
          }
          if (field.type === 'payment') {
            /**
             * Here is where we setup the payment info properties we set up based on payment processor for v-model
             */
            if (field.payment_processor === 'authnet') {
              field.payment_info = {
                card_number: '',
                card_cvv: '',
                expiration_year: '',
                expiration_month: '',
                first_name: '',
                last_name: '',
                address: '',
                city: '',
                state: '',
                zip: '',
                country: ''
              }
            }
            /**
             * Default label
             */
            field.label = {
              en: ''
            }
            field.widget_id = Vue.prototype.$uuid.v4()
          } else if (field.type === 'html') {
            delete field.html.am
            /**
             * Default label
             */
            field.label = {
              en: ''
            }
          }
        })
      }
      const duplicateCheckPassed = this.duplicateFieldNameCheck()
      if (!duplicateCheckPassed) {
        return
      }
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        devUUIDFromFormbuilder: this.$data.changeLogModel.currentDevUUID,
        data: form
      }
      await this.$store.dispatch('formbuilder/saveForm', payload)
      this.$store.commit('formbuilder/SET_FORM_EXISTS', true)
      Container.get(Notification).success('Registration form successfully saved.')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.submitting = false
      this.$store.dispatch('common/hideLoader')
    }
  }

  getLabel(field: any) {
    if (field.label?.[this.$data.languageToUse] !== undefined) {
      return field.label?.[this.$data.languageToUse]
    }
    return field.label
  }

  duplicateFieldNameCheck() {
    const fieldsOnForm = this.getFieldsOnForm()
    const fieldLabelsOnForm = fieldsOnForm.map(field => (this.getLabel(field)).toLowerCase())
    const formDuplicates: any[] = fieldLabelsOnForm.filter((label, index, array) => array.indexOf(label) !== index && label !== '')

    if (formDuplicates.length) {
      Container.get(Notification).error(`${formDuplicates.map(word => `"${word}"`).join(', ')} ${formDuplicates.length > 1 ? 'are' : 'is'} on the form already`)
      return false
    }
    const fieldLabelsInExistingFields = this.existingFields.map(field => (this.getLabel(field)).toLowerCase())
    const existingFieldsDuplicates: any[] = []

    for (const field of this.existingFields) {
      const label = this.getLabel(field).toLowerCase()
      if (fieldLabelsOnForm.includes(label)) {
        existingFieldsDuplicates.push(label)
      }
    }
    if (existingFieldsDuplicates.length) {
      Container.get(Notification).error(`${existingFieldsDuplicates.map(word => `"${word}"`).join(', ')} ${existingFieldsDuplicates.length > 1 ? 'are' : 'is'} can be found in the existing fields section`)
      return false
    }
    return true
  }

  allowShowReadOnlyIfCompleteOrReadOnlyIfSet(field: any) {
    return field.type === 'text' || field.type === 'dropdown' || field.type === 'checkbox' || field.type === 'radio' || field.type === 'textarea'
  }

  onCopy() {
    Container.get(Notification).success('Successfully copied uuid.')
  }

  onError() {
    Container.get(Notification).success('There was an error copying the uuid. Please refresh and try again.')
  }

  addNewOptionGroup(page, field) {
    this.handleDeleteField(page, field.field)
    this.handleSaveForm()
    this.$router.push({
      name: 'level-two.modules.registration.options.new'
    })
  }

  private isVisible(field: Record<string, any>): boolean {
    if (field.visible || field.visible === undefined) return true
    return false
  }

  private async fetchData() {
    try {
      this.$store.commit('formbuilder/SET_FORM_STATE', [])
      this.$store.commit('formbuilder/SET_FORM_EXISTS', false)
      this.$store.commit('option/SET_OPTION_GROUPS', [])
      this.$store.commit('formbuilder/SET_EVENT_FIELDS', [])
      this.$store.commit('event/SET_EVENT_ALL_CONTENT', null)
      this.$data.loading = true
      const event_uuid: string = this.$route.params.event_uuid
      const changelogData = { type: 'form', subtype: 'default/all', event_uuid }
      const deployData = { type: 'form', sub_type: 'default', event_uuid }
      const promises = [
        this.$store.dispatch('formbuilder/getForm', { event_uuid }),
        this.$store.dispatch('option/getOptionsGroup', { event_uuid }),
        await this.$store.dispatch('formbuilder/getEventFields', { event_uuid }),
        this.$store.dispatch('event/getEventAllContent', event_uuid),
        this.$store.dispatch('event/getChangelog', changelogData),
        this.$store.dispatch('event/getCurrentlyDeployedLiveUUID', deployData),
        this.$store.dispatch('event/getCurrentDevUUID', deployData),
        this.$store.dispatch('event/getParticipantFields', this.$route.params.event_uuid)
      ]
      await Promise.all(promises)
      const response = await this.$store.dispatch('formbuilder/getEventFields', { event_uuid })
      if (response.data) {
        this.$data.glossaryItems = response.data
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private startFormPolling() {
    this.$data.formPollingId = setInterval(() => {
      const deployData = {
        type: 'form',
        subtype: 'default',
        event_uuid: this.$route.params.event_uuid,
        currentDevUUID: this.$data.changeLogModel.currentDevUUID
      }
      this.$store.dispatch('formbuilder/compareFormVersionsDuringPolling', deployData)
    }, 30000)
  }

  private stopFormPolling() {
    if (this.$data.formPollingId) {
      clearInterval(this.$data.formPollingId)
    }
  }

  // #endregion
}
